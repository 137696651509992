import http from "@/services/http-common";
import qs from "qs";
import ResponseData from "@/types/ResponseData";

class SearchService {
  searchAvailableTags(tag: string): Promise<ResponseData> {
    return http.get("/search", {
      params: { tag: tag },
      paramsSerializer: (params: Record<string, any>) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  }
}

export default new SearchService();
